@font-face {
    font-family: neon;
    src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf);
  }

  .neon-blue {
    font-family: neon;
    color: #80FFFF;
    font-size: 60px;
    text-shadow: 0 0 3vw #2356ff;
    animation: neon-blue 2s linear infinite;
    margin-bottom: 20px;
   
  }
  .neon-blue2 {
    font-family: neon;
    color: #80FFFF;
    font-size: 20px;
    text-shadow: 0 0 3vw #2356ff;
    animation: neon-blue 2s linear infinite;
    margin-bottom:7em;
    
  }
  @media (max-width: 600px) {
    .neon-blue{
      font-size: 40px;
    
    }
    .neon-blue2{
      font-size: 16px;
    }
    
  }

  /* @keyframes neon-blue {
    0%,
    100% {
      text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff, 0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe,
        0.5vw 0.5vw 0.1vw #147280;
      color: #28d7fe;
    }
    50% {
      text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180, 0 0 5vw #082180, 0 0 0.2vw #082180,
        0.5vw 0.5vw 0.1vw #0a3940;
      color: #146c80;
    }
  } */